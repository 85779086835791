<template>
    <div style="display: contents">

        <!--    CHIP    -->
        <v-chip
            close-icon="mdi-plus-circle"
            color="orange darken-4"
            :style="{cursor: !preventGroupChange ? 'pointer' : 'default'}"
            :close="!!iGroup"
            @click.stop="!preventGroupChange && loadGroups()"
            @click:close="loadMembers"
            label
            outlined
        >
            <v-icon small left>mdi-account-multiple</v-icon>
            {{ iGroup || 'No group assigned' }}
        </v-chip>

        <!--    MEMBERS LIST    -->
        <v-row class="pt-3" no-gutters>
            <v-col :cols="membersListCols">
                <template v-for="(mem, i) in iMembers || []">
                    <v-hover v-slot:default="{ hover }" :key="i">
                        <v-row
                            :class="['rounded pl-2', {'grey lighten-3': hover}]"
                            dense
                        >
                            <v-col cols="10" class="body-2">
                                {{ mem.full_name }}
                            </v-col>
                            <v-col cols="2" class="d-flex align-center justify-end">
                                <v-icon
                                    color="red"
                                    style="cursor: pointer"
                                    @click="showRemoveMember(mem)"
                                    small
                                >mdi-close-circle</v-icon>
                            </v-col>
                        </v-row>
                    </v-hover>
                </template>
            </v-col>
        </v-row>

        <!--    DIALOGS    -->
        <v-dialog
            v-model="config.chgGroupDlg"
            max-width="350"
        >
            <v-tabs
                v-model="config.tab"
                grow
            >
                <v-tab key="exist">Existing Group</v-tab>
                <v-tab key="new">New Group</v-tab>
            </v-tabs>
            <v-card
                class="rounded-0"
            >
                <v-card-text class="pt-9">
                    <v-tabs-items
                        v-model="config.tab"
                        class="mt-n1"
                    >
                        <v-tab-item key="exist">
                            <v-select
                                :value="iGroup || 'No Group Selected'"
                                @input="newGroup = $event"
                                item-text="name"
                                item-value="name"
                                :items="groupList"
                                hide-details
                                dense
                                outlined
                            />
                        </v-tab-item>
                        <v-tab-item key="new">
                            <v-text-field
                                @input="newGroup = newGroupPrefix + $event"
                                :prefix="newGroupPrefix"
                                hide-details
                                autofocus
                                dense
                            />
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="config.chgGroupDlg = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        :loading="config.loading"
                        :disabled="!newGroup || config.loading"
                        @click="chgGroup"
                    >
                        Change
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="config.newMemberDlg"
            max-width="350"
            persistent
        >
            <v-card class="rounded-0">
                <v-card-title>
                    Add Member
                </v-card-title>
                <v-card-subtitle>
                    {{ iGroup }}
                </v-card-subtitle>
                <v-card-text>
                    <SearchPeople
                        v-model="newMember"
                        label="Search people"
                        ref="searchPeopleWidget"
                        users-only
                        ldap-populate
                        dense
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="cancelAddNewMember"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        :disabled="!newMember || config.loading"
                        :loading="config.loading"
                        @click="addMember"
                        text
                    >
                        Add Member
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="config.removeMemberDlg"
            max-width="400"
        >
            <v-card>
                <v-card-title>
                    Remove Member
                </v-card-title>
                <v-card-subtitle class="pb-1">
                    {{ iGroup }}
                </v-card-subtitle>
                <v-card-text>
                    Are you sure you want to remove <strong>{{ remMember.full_name }}</strong>?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="blue darken-1"
                        text
                        @click="config.removeMemberDlg = false"
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!remMember || config.loading"
                        :loading="config.loading"
                        @click="removeMember"
                    >
                        Remove Member       
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</template>
<script>
import axios from 'axios';
import { reactive, onMounted, ref, computed } from '@vue/composition-api';
import SearchPeople from '@/components/population/SearchPeople';
export default {
    name: 'ManageGroup',
    components: {
        SearchPeople
    },
    props: {
        group: {
            type: String,
            default: ''
        },
        groupFilter: {
            type: String
        },
        newGroupPrefix: {
            type: String
        },
        division: {
            type: Object,
            default() {
                return {}
            }
        },
        members: {
            type: Array,
            default() {
                return []
            }
        },
        membersListCols: {
            type: String,
            default: "9"
        },
        preventGroupChange: {
            type: Boolean,
            default: false
        },
        sync: {
            type: Boolean,
            default: true
        }
    },
    setup(props, { root, refs }) {

        const store = root.$store;

        const config = reactive({
            chgGroupDlg: false,
            newMemberDlg: false,
            removeMemberDlg: false,
            tab: 'exist',
            loading: false
        });

        const groupList = ref(['No Group Selected']);
        const newGroup = ref('');
        const newMember = ref(null);
        const remMember = ref({});

        const iGroup = ref('');
        const iMembers = ref([]);

        onMounted(() => {
            iGroup.value = props.group;
            iMembers.value = props.members;
        });



        const groupType = computed(() => {

            const g = iGroup.value || props.group || props.groupFilter || props.newGroupPrefix;

            
            let t = {name: 'UNSPECIFIED', type: 'UNSPECIFIED'};

            if (g.includes('hr-')) {
                t.name = 'HR'
            } else if (g.includes('pur-')) {
                t.name = 'PUR'
            }

            if (g.includes('approver')) {
                t.type = 'APPROVER'
            } else if (g.includes('requester')) {
                t.type = 'REQUESTER'
            }
            return t;
        });


        const loadGroups = async () => {
            let url = '/groups/load/';

            if (props.groupFilter) url += `?filter=${props.groupFilter}`

            const response = await axios.get(url);
            groupList.value = groupList.value.concat(response.data);
            config.chgGroupDlg = true;
        };

        const chgGroup = async () => {
            config.loading = true;

            const ng = newGroup.value;

            let payload = {
                newGroup: ng,
                division: Object.fromEntries(
                    Object.entries(props.division).filter(
                        ([key]) => ['id', 'name'].includes(key)
                    )
                ),
                groupType: groupType.value
            };

            const response = await axios.post('/groups/change_division_group/', payload);

            if (!response.data.error) {
                iGroup.value = response.data.group.name;
                iMembers.value = response.data.group.members;
                store.commit('setSuccessSnack', `${response.data.message}`);
                config.chgGroupDlg = false;
            } else {
                store.commit('setErrorSnack', `Error: ${response.data.message}`);
            }

            config.loading = false;

        };

        const loadMembers = () => {
            newMember.value = null;
            config.newMemberDlg = true;
        };

        const setMembers = (m) => {
            iMembers.value = m;
        };

        const addMember = async () => {
            config.loading = true;

            let payload = {
                newMember: newMember.value,
                division: Object.fromEntries(
                    Object.entries(props.division).filter(
                        ([key]) => ['id', 'name'].includes(key)
                    )
                ),
                toGroup: iGroup.value,
                groupType: groupType.value,
                sync: props.sync
            };

            const response = await axios.post('/groups/add_member_to_group/', payload);

            if (!response.data.error) {
                iMembers.value = response.data.group.members;
                config.newMemberDlg = false;
                refs.searchPeopleWidget.clear();
                store.commit('setSuccessSnack', `${response.data.message}`);
            } else {
                store.commit('setErrorSnack', `Error: ${response.data.message}`);
            }

            config.loading = false;
        };

        const cancelAddNewMember = () => {
            refs.searchPeopleWidget.clear();
            newMember.value = null;
            config.newMemberDlg = false;
        };

        const showRemoveMember = (m) => {
            remMember.value = m;
            config.removeMemberDlg = true;
        };

        const removeMember = async () => {
            config.loading = true;
            let payload = {
                remMember: remMember.value,
                fromGroup: iGroup.value,
                sync: props.sync
            };
            const response = await axios.post(`/groups/remove_member_from_group/`, payload);
            if (!response.data.error) {
                iMembers.value = response.data.group.members;
                config.removeMemberDlg = false;
                store.commit('setSuccessSnack', `${response.data.message}`);
            } else {
                store.commit('setErrorSnack', `Error: ${response.data.message}`);
            }
            config.loading = false;
        };

        return {
            config,
            loadGroups,
            groupList,
            newGroup,
            chgGroup,
            newMember,
            loadMembers,
            cancelAddNewMember,
            showRemoveMember,
            addMember,
            remMember,
            removeMember,
            iGroup,
            iMembers,
            setMembers
        }
        
    },
}
</script>